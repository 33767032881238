<template>
  <span>
    <page-header icon="fa fa-list-alt" :title="title" :links="getLinks()"></page-header>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div v-if="showLists">
      <div ref="printMe" v-if="!isLoading(loadingName)">
        <h2 class="hide-here" v-if="tag">{{ tag.name }}</h2>
        <div class="individuals-list" ref="excelMe">
          <x-table
            id="current"
            :columns="columns"
            :items="items"
            :show-counter="true"
            verbose-name="membre actif"
            verbose-name-plural="membres actifs"
          ></x-table>
          <div v-if="(removedItems.length > 0) || (deadItems.length > 0)" class="no-print">
            <b-form-checkbox id="viewOld" v-model="viewOld">Voir les anciens membres</b-form-checkbox>
          </div>
          <div v-if="viewOld">
            <x-table
              id="dead"
              :columns="deathColumns"
              :items="deadItems"
              :show-counter="true"
              verbose-name="membre décédé"
              verbose-name-plural="membres décédés"
              v-if="deadItems.length"
            ></x-table>
            <x-table
              id="prev"
              :columns="oldColumns"
              :items="removedItems"
              :show-counter="true"
              verbose-name="ancien membre"
              verbose-name-plural="anciens membres"
              v-if="removedItems.length"
            ></x-table>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <b-row>
        <b-col>
          <b>Agenda</b>
        </b-col>
        <b-col cols="3" class="text-right">
          <a class="btn-sm btn btn-secondary" href @click.prevent="onAddEvent">
            <i class="fa fa-plus"></i> Ajouter
          </a>
          &nbsp;
          <a
            class="btn-sm btn btn-secondary"
            href
            @click.prevent="onChangePlace"
            :class="{ disabled: !isForcePlaceActive, }"
          >
            <i class="fa fa-location-dot"></i> Modifier le lieu
          </a>
          &nbsp;
          <a
            class="btn-sm btn btn-secondary"
            href
            @click.prevent="onDeletePlaces"
            :class="{ disabled: !isForcePlaceActive, }"
          >
            <i class="fa fa-location-dot"></i> Supprimer les dates
          </a>
        </b-col>
      </b-row>
      <div ref="printMe">
        <agenda-dates-table
          :tag="tag"
          :show-header="false"
          :force-add-event="forceAddEvent"
          :force-change-places="forceChangePlace"
          :delete-change-places="deleteChangePlaces"
          :force-reload="forceReload"
          @forced="onForceDone()"
          @selected="onSelectionDone($event)"
          @loaded="activeDates = $event.dates"
        ></agenda-dates-table>
      </div>
    </div>
    <add-individuals-to-list
      :tag="tag"
      @done="loadList()"
    >
    </add-individuals-to-list>
    <duplicate-tag-modal
      :individuals="individuals"
      :tag="tag"
      :tags="allTags"
      @done="viewTag($event)"
    ></duplicate-tag-modal>
    <confirm-modal
      name="remove-individual-from-list"
      :object="selectedItem"
      title="Supprimer une personne de la liste"
      :text="getRemoveIndividualMessage()"
      @confirmed="removeFromList"
    >
    </confirm-modal>
    <b-modal
      dialog-class="modal-lg"
      id="bv-modal-edit-comments-tag"
      @ok.prevent="onEditTagComments"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Modifier"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-pen"></i> Modifier un commentaire</b>
      </template>
      <b-row>
        <b-col>
          <b-form-group
            id="comments-group"
            label="Commentaire"
            label-for="comments"
          >
            <b-form-input
              id="comments"
              type="text"
              v-model="comments"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import XTable from '@/components/Controls/Table/Table'
import PageHeader from '@/components/Layout/PageHeader'
import ConfirmModal from '@/components/Modals/ConfirmModal'
import AgendaDatesTable from '@/components/Agenda/AgendaDatesTable.vue'
import AddIndividualsToList from '@/components/Tags/AddIndividualsToList'
import DuplicateTagModal from '@/components/Tags/DuplicateTagModal'
import { AgendaMixin } from '@/mixins/agenda'
import { BackendMixin } from '@/mixins/backend'
import router from '@/router'
import { makeFamily } from '@/types/families'
import { makeEntity, makeIndividual, makeTag } from '@/types/people'
import { BackendApi, openDocument } from '@/utils/http'
import { slugify } from '@/utils/strings'
import { dateToString } from '@/filters/texts'

export default {
  name: 'Tag',
  mixins: [BackendMixin, AgendaMixin],
  components: {
    AgendaDatesTable,
    ConfirmModal,
    PageHeader,
    LoadingGif,
    XTable,
    AddIndividualsToList,
    DuplicateTagModal,
  },
  props: {
    id: [String, Number],
  },
  data() {
    return {
      loadingName: 'tagDetail',
      tag: null,
      rawItems: [],
      selectedItem: null,
      individuals: [],
      allTags: [],
      tagMembershipId: 0,
      comments: '',
      columns: [],
      oldColumns: [],
      deathColumns: [],
      showLists: true,
      viewOld: true,
    }
  },
  async mounted() {
    await this.loadList()
    this.columns = this.getColumns(false, false)
    this.deathColumns = this.getColumns(false, true)
    this.oldColumns = this.getColumns(true, false)
  },
  computed: {
    title() {
      return this.tag ? this.tag.name : ''
    },
    items() {
      return this.rawItems.map(
        elt => this.makeItem(elt)
      ).filter(
        elt => !elt.removed && !elt.individual.deathDate
      )
    },
    deadItems() {
      return this.rawItems.map(
        elt => this.makeItem(elt)
      ).filter(
        elt => !elt.removed && elt.individual.deathDate
      )
    },
    removedItems() {
      return this.rawItems.map(
        elt => this.makeItem(elt)
      ).filter(
        elt => elt.removed
      )
    },
    hasDeathCol() {
      return this.removedItems.filter(
        elt => elt.individual.deathDate
      ).length > 0
    },
  },
  methods: {
    ...mapActions(['addSuccess', 'addError', 'addWarning']),
    ...mapMutations(['startLoading', 'endLoading']),
    getColumns(removed, death) {
      const columns = [
        {
          name: 'fullName',
          label: 'Nom',
          maxWidth: '150px',
          onClick: item => {
            this.showIndividualSidebar(item.individual)
          },
          isLink: true,
          linkUrl: item => {
            if (item.family) {
              return router.resolve({ name: 'families-detail', params: { entityId: item.entityId, }, }).href
            } else {
              return router.resolve({ name: 'entities-detail', params: { entityId: item.entityId, }, }).href
            }
          },
        },
        {
          name: 'email',
          label: 'Email',
          displayAs: 'email',
          maxWidth: '200px',
        },
        {
          name: 'cellPhone',
          label: 'Téléphone portable',
          alignCenter: true,
          displayAs: 'phone',
          maxWidth: '120px',
        },
        {
          name: 'fullAddress',
          label: 'Adresse',
          alignCenter: false,
          maxWidth: '150px',
        },
        {
          name: 'comments',
          label: 'Commentaire',
          placeholder: '<span class="no-print">Ajouter un commentaire <i class="fas fa-pen"></i></span>',
          onHover: '<i class="fas fa-pen no-print"></i>',
          onClick: this.onCommentsClicked,
          isLink: item => {
            return (item)
          },
          maxWidth: '150px',
        },
        {
          name: 'addedOn',
          label: 'Depuis le',
          maxWidth: '80px',
          dateFormat: 'DD/MM/YYYY',
        }
      ]
      if (death || this.hasDeathCol) {
        columns.push(
          {
            name: 'deathOn',
            label: 'Décès le',
            dateFormat: 'DD/MM/YYYY',
          }
        )
      }
      if (!removed) {
        columns.push(
          {
            name: 'delete',
            label: '',
            hideFilter: true,
            alignRight: true,
            maxWidth: '20px',
            isLink: item => {
              return (item)
            },
            onClick: item => {
              this.onRemoveFromList(item)
            },
          }
        )
      } else {
        columns.push(
          {
            name: 'removedOn',
            label: 'Départ le',
            dateFormat: 'DD/MM/YYYY',
          }
        )
      }
      return columns
    },
    onCommentsClicked(item) {
      this.tagMembershipId = item.id
      this.comments = item.comments
      this.$bvModal.show('bv-modal-edit-comments-tag')
    },
    getRemoveIndividualMessage() {
      if (this.selectedItem) {
        let text = 'Supprimer ' + this.selectedItem.fullName + ' de la liste ' + this.tag.name + '?'
        text += ' La suppression du dernier membre supprimera la liste.'
        return text
      } else {
        return ''
      }
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'liste-' + slugify(this.tag.name)
      const docContent = this.$refs.excelMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async printMe() {
      let docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = 'liste-' + slugify(this.tag.name)
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getLinks() {
      let links = []
      if (this.tag && this.tag.id) {
        links = [
          {
            id: 1,
            label: 'Excel',
            callback: this.excelMe,
            icon: 'far fa-file-excel',
            cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
          },
          {
            id: 2,
            label: 'Pdf',
            callback: this.printMe,
            icon: 'fa fa-file-pdf',
            cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
          }
        ]
        if (this.hasPerm('people.add_tag')) {
          links.push(
            {
              id: 3,
              label: 'Ajouter une personne',
              callback: this.addIndividualToTag,
              icon: 'fa fa-plus',
              cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
            }
          )
          links.push(
            {
              id: 4,
              label: 'Dupliquer / Fusionner',
              callback: this.duplicateTag,
              icon: 'fas fa-copy',
              cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
            }
          )
          links.push(
            {
              id: 5,
              label: this.showLists ? 'Agenda' : 'Liste',
              callback: () => {
                this.showLists = !this.showLists
              },
              icon: this.showLists ? 'fa fa-calendar' : 'fa fa-list',
              cssClass: 'btn-secondary',
            }
          )
        }
      }
      return links
    },
    async onEditTagComments() {
      let url = '/api/people/edit-tag-comments/'
      const backendApi = new BackendApi('patch', url)
      const data = {
        membership: this.tagMembershipId,
        comments: this.comments,
      }
      try {
        const resp = await backendApi.callApi(data)
        await this.addSuccess(resp.data.message)
        await this.loadList()
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.$bvModal.hide('bv-modal-edit-comments-tag')
    },
    onRemoveFromList(item) {
      this.selectedItem = item
      this.$bvModal.show('bv-confirm-modal:remove-individual-from-list')
    },
    async removeFromList(event) {
      let url = '/api/people/delete-tags/'
      const backendApi = new BackendApi('delete', url)
      const data = {
        'individual': event.object.individualId,
        'tag': this.tag.id,
      }
      try {
        const resp = await backendApi.callApi(data)
        await this.addSuccess(resp.data.message)
        await this.loadList()
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadList() {
      this.startLoading(this.loadingName)
      let url = '/api/people/get-individuals-by-tag/' + this.id + '/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.tag = makeTag(resp.data.tag)
        this.individuals = resp.data.items.filter(
          elt => !elt['removed_on']
        ).map(elt => elt.individual.id)
        this.rawItems = resp.data.items.map(
          elt => {
            let entity, family
            if (elt.entity) {
              family = makeFamily(elt.entity)
              entity = family.entity
            } else {
              family = null
              entity = makeEntity(elt.entity)
            }
            return {
              individual: makeIndividual(elt.individual),
              entity: entity,
              family: family,
              addedOn: elt['added_on'],
              removedOn: elt['removed_on'],
              comments: elt['comments'],
              membership: elt['membership'],
            }
          }
        )
      } catch (err) {
        if (err.response && err.response.status === 404) {
          await this.addWarning('La liste demandée a été supprimée')
          await router.push({ name: 'entities-list', })
        } else {
          await this.addError(this.getErrorText(err))
        }
      }
      this.endLoading(this.loadingName)
    },
    makeItem(elt) {
      let deathOn = ''
      if (elt.individual.deathDate) {
        deathOn = dateToString(elt.individual.deathDate, 'YYYY-MM-DD')
      }
      return {
        id: elt.membership,
        fullName: elt.individual.lastAndFirstName(),
        individual: elt.individual,
        email: elt.individual.email,
        cellPhone: elt.individual.cellPhone,
        individualId: elt.individual.id,
        entityId: elt.entity.id,
        family: elt.entity.family,
        fullAddress: elt.entity.fullAddress(),
        addedOn: elt.addedOn ? dateToString(elt.addedOn, 'YYYY-MM-DD') : '',
        deathOn: deathOn,
        comments: elt.comments,
        removed: elt.removedOn !== null,
        removedOn: elt.removedOn ? dateToString(elt.removedOn, 'YYYY-MM-DD') : '',
        delete: '<a href><i class="fas fa-trash no-print" style="color: #cc0000"></i></a>',
      }
    },
    addIndividualToTag() {
      this.$bvModal.show('bv-modal-add-individual-to-tag')
    },
    async loadAllTags() {
      let url = '/api/people/tags/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.allTags = resp.data.map(elt => elt.name)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    duplicateTag() {
      this.loadAllTags()
      this.$bvModal.show('bv-modal-duplicate-tag')
    },
    async viewTag(tagId) {
      await router.push({ name: 'tag-detail', params: { id: tagId, }, })
      await this.loadList()
    },
  },
}
</script>

<style scoped>
</style>
